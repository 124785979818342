import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import ArticleHeader from "../components/ArticleHeader"
import ArticleBody from "../components/ArticleBody"
import ArticleFooter from "../components/ArticleFooter"
import ArticleRelated from "../components/ArticleRelated"
import Seo from "../components/Seo"

export default function Article({ data }) {
  // Data destructuring
  const {
    article,
    articleBody,
    relatedByIssue,
    relatedBySection,
    allWithTags,
    seo,
  } = data
  const {
    _id,
    title,
    author,
    date,
    issue,
    section,
    topics,
    tags,
    relatedTitle,
    relatedArticles,
    cover,
  } = article
  let tagsIDs = tags ? tags.map(tag => tag._id) : []
  let authorName = author ? author.name.fi : ""

  return (
    <Layout>
      <Seo seoData={seo} />

      <ArticleHeader
        titleRu={title.ru}
        titleFi={title.fi}
        authorName={authorName}
        publishDate={date}
        sectionData={section}
        issueData={issue}
        topicData={topics?.[0]}
        cover={cover}
      />

      <ArticleBody body={articleBody.body} />

      <ArticleFooter authorData={author} tagsData={tags} />

      <ArticleRelated
        id={_id}
        tagsIDs={tagsIDs}
        relatedTitle={relatedTitle}
        relatedArticles={relatedArticles}
        relatedByIssue={relatedByIssue}
        relatedBySection={relatedBySection}
        allWithTags={allWithTags}
      />
    </Layout>
  )
}

export const query = graphql`
  fragment PortableText on SanityBlock {
    _key
    _type
    list
    style
    children {
      _key
      _type
      marks
      text
    }
  }

  fragment BlockSettings on SanityBlockSettings {
    _type
    rules {
      ... on SanityBreakpointsRule {
        _type
        rule
        breakpoint
      }
    }
  }

  fragment ContentBodyCustom on SanityBlockButtonOrBlockCardOrBlockGalleryOrBlockHeadingOrBlockHighlightOrBlockImageOrBlockLocaleLinkGroupOrBlockLocaleTextMinOrBlockSpacerOrBlockWidget {
    ... on SanityBlockLocaleTextMin {
      _key
      _type
      _rawContentRu
      _rawContentFi
      contentFi {
        ...PortableText
      }
      contentRu {
        ...PortableText
      }
    }
    ... on SanityBlockHeading {
      _key
      _type
      heading {
        _key
        _type
        fi
        ru
      }
    }
    ... on SanityBlockHighlight {
      _key
      _type
      highlight {
        _type
        fi
        ru
      }
      author {
        _type
        fi
        ru
      }
      subtitle {
        _type
        fi
        ru
      }
    }
    ... on SanityBlockLocaleLinkGroup {
      _key
      _type
      localeCaption {
        ru
        fi
      }
      links {
        ... on SanityBlockLocaleLink {
          _key
          _type
          url
          localeCaption {
            ru
            fi
          }
          mark
          icon
        }
      }
    }
    ... on SanityBlockButton {
      _key
      _type
      caption
      url
    }
    ... on SanityBlockCard {
      _key
      _type
      author {
        _key
        _type
        fi
        ru
      }
      content {
        _key
        _rawContentFi
        _rawContentRu
        contentFi {
          ...PortableText
        }
        _type
        contentRu {
          ...PortableText
        }
      }
      image {
        _key
        _type
        alt
        noScale
        settings {
          ...BlockSettings
        }
        asset {
          _id
          metadata {
            dimensions {
              aspectRatio
              height
              width
            }
          }
        }
      }
      links {
        _key
        _type
        localeCaption {
          _key
          _type
          fi
          ru
        }
        icon
        mark
        url
      }
      title {
        _key
        _type
        fi
        ru
      }
      type
    }
    ... on SanityBlockImage {
      _key
      _type
      alt
      asset {
        _id
      }
      caption
      width
      copyrightUrl
      settings {
        ...BlockSettings
      }
    }
    ... on SanityBlockGallery {
      _key
      _type
      width
      images {
        _key
        _type
        alt
        caption
        copyrightUrl
        asset {
          _id
        }
      }
      settings {
        ...BlockSettings
      }
    }
    ... on SanityBlockSpacer {
      _key
      _type
      height
      settings {
        ...BlockSettings
      }
    }
    ... on SanityBlockWidget {
      _key
      _type
      type
      url
      caption
      autoplay
      settings {
        ...BlockSettings
      }
    }
  }

  query GetArticle($id: String, $issueId: String, $sectionId: String) {
    article: sanityArticle(_id: { eq: $id }) {
      _id
      title {
        fi
        ru
      }
      author {
        about {
          fi
          ru
        }
        links {
          type
          url
          _key
        }
        name {
          fi
          ru
        }
        photo {
          copyright
          asset {
            _id
            url
          }
        }
        slug {
          current
        }
      }
      date
      issue {
        number
        title {
          fi
          ru
        }
        slug {
          current
        }
      }
      topics {
        title {
          fi
          ru
        }
        slug {
          current
        }
      }
      section {
        slug {
          current
        }
        title {
          fi
          ru
        }
      }
      cover {
        asset {
          _id
        }
        caption
        isVertical
        alt
        copyrightUrl
      }
      tags {
        _id
        id
        slug {
          current
        }
        title {
          fi
          ru
        }
      }
      relatedTitle {
        fi
        ru
      }
      relatedArticles {
        _id
        _type
        date
        slug {
          current
        }
        cover {
          asset {
            _id
          }
        }
        thumb {
          asset {
            _id
          }
        }
        title {
          fi
          ru
        }
        description {
          fi
          ru
        }
        author {
          name {
            fi
            ru
          }
        }
        section {
          title {
            ru
            fi
          }
          slug {
            current
          }
        }
      }
    }

    articleBody: sanityArticle(_id: { eq: $id }) {
      body {
        ... on SanityBlockLocaleText {
          _key
          _type
          _rawContentRu
          _rawContentFi
          contentFi {
            ...PortableText
          }
          contentRu {
            ...PortableText
          }
        }
        ... on SanityBlockLocaleText {
          _key
          _type
          _rawContentRu
          _rawContentFi
          contentFi {
            ...PortableText
          }
          contentRu {
            ...PortableText
          }
          settings {
            ...BlockSettings
          }
        }
        ... on SanityBlockHeading {
          _key
          _type
          heading {
            _key
            _type
            fi
            ru
          }
        }
        ... on SanityBlockHighlight {
          _key
          _type
          highlight {
            _type
            fi
            ru
          }
          author {
            _type
            fi
            ru
          }
          subtitle {
            _type
            fi
            ru
          }
        }
        ... on SanityBlockImage {
          _key
          _type
          alt
          caption
          width
          copyrightUrl
          asset {
            _id
          }
          settings {
            ...BlockSettings
          }
        }
        ... on SanityBlockGallery {
          _key
          _type
          width
          images {
            _key
            _type
            alt
            caption
            copyrightUrl
            asset {
              _id
            }
          }
          settings {
            ...BlockSettings
          }
        }
        ... on SanityBlockDivider {
          _key
          _type
          style
          custom
          width
        }
        ... on SanityBlockLocaleButton {
          _key
          _type
          buttonFi {
            _key
            _type
            caption
            url
          }
          buttonRu {
            _key
            caption
            _type
            url
          }
        }
        ... on SanityBlockWidget {
          _key
          _type
          type
          url
          caption
          autoplay
          settings {
            ...BlockSettings
          }
        }
        ... on SanityBlockCardsGroup {
          _key
          _type
          layout
          cards {
            _key
            _type
            type
            author {
              fi
              ru
            }
            image {
              asset {
                _id
                metadata {
                  dimensions {
                    aspectRatio
                    height
                    width
                  }
                }
              }
              alt
              noScale
            }
            links {
              _key
              _type
              url
              mark
              icon
              localeCaption {
                fi
                ru
              }
            }
            content {
              _key
              _type
              _rawContentRu
              _rawContentFi
              contentFi {
                ...PortableText
              }
              contentRu {
                ...PortableText
              }
            }
            title {
              _key
              _type
              fi
              ru
            }
          }
          settings {
            ...BlockSettings
          }
        }
        ... on SanityBlockCustomLayout {
          _key
          _type
          layout
          leftBody {
            ...ContentBodyCustom
          }
          middleBody {
            ...ContentBodyCustom
          }
          rightBody {
            ...ContentBodyCustom
          }
          settings {
            ...BlockSettings
          }
        }
      }
    }

    relatedByIssue: allSanityArticle(
      filter: { issue: { _id: { eq: $issueId } } }
    ) {
      edges {
        node {
          _id
          _type
          date
          slug {
            current
          }
          cover {
            asset {
              _id
            }
          }
          thumb {
            asset {
              _id
            }
          }
          title {
            fi
            ru
          }
          description {
            fi
            ru
          }
          author {
            name {
              fi
              ru
            }
          }
          section {
            title {
              ru
              fi
            }
            slug {
              current
            }
          }
        }
      }
    }

    relatedBySection: allSanityArticle(
      filter: { section: { _id: { eq: $sectionId } } }
    ) {
      edges {
        node {
          _id
          _type
          date
          slug {
            current
          }
          cover {
            asset {
              _id
            }
          }
          thumb {
            asset {
              _id
            }
          }
          title {
            fi
            ru
          }
          description {
            fi
            ru
          }
          author {
            name {
              fi
              ru
            }
          }
          section {
            title {
              ru
              fi
            }
            slug {
              current
            }
          }
        }
      }
    }

    allWithTags: allSanityArticle(
      filter: { tags: { elemMatch: { _id: { ne: null } } } }
    ) {
      edges {
        node {
          _id
          _type
          date
          slug {
            current
          }
          cover {
            asset {
              _id
              originalFilename
            }
          }
          thumb {
            asset {
              _id
            }
          }
          title {
            fi
            ru
          }
          description {
            fi
            ru
          }
          author {
            name {
              fi
              ru
            }
          }
          section {
            title {
              ru
              fi
            }
            slug {
              current
            }
          }
          tags {
            _id
          }
        }
      }
    }
    seo: sanityArticle(_id: { eq: $id }) {
      _id
      _type
      title {
        fi
        ru
      }
      description {
        fi
        ru
      }
      slug {
        current
      }
      author {
        name {
          fi
          ru
        }
      }
      date
      section {
        slug {
          current
        }
        title {
          fi
          ru
        }
      }
      topics {
        title {
          fi
          ru
        }
        slug {
          current
        }
        seo {
          metaKeywords
        }
      }
      cover {
        asset {
          _id
        }
      }
      thumb {
        asset {
          _id
        }
      }
      seo {
        metaTitle
        metaDescription
        metaKeywords
        sharedImage {
          asset {
            _id
          }
        }
        preventIndexing
      }
    }
  }
`
